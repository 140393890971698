<template>
	<div class="page">
		<div class="page-row">
			<v-card class="info-card">
				<AdminUserReportInfoTable :data="firstSection" />
			</v-card>
			<v-card class="guilds">
				<AdminUserReportInfoGuilds :guilds="user.guilds" :ips="user.ips" />
			</v-card>
		</div>
		<div class="page-row">
			<v-card class="reports">
				<AdminUserReportInfoComments
					:reports="reports"
					@refresh="$emit('refresh')"
				/>
			</v-card>
		</div>
		<div class="page-row">
			<!--
      <v-card class="iframe">
        <iframe :src="iframeUrl" height="100%" width="100%"></iframe>
      </v-card>
      -->
		</div>
	</div>
</template>

<script>
import AdminUserReportInfoTable from '@/views/admin/components/AdminUserReportInfoTable';
import AdminUserReportInfoGuilds from '@/views/admin/components/AdminUserReportInfoGuilds';
import AdminUserReportInfoComments from '@/views/admin/components/AdminUserReportInfoComments';

export default {
	name: 'AdminUserReportInfo',
	components: {
		AdminUserReportInfoTable,
		AdminUserReportInfoGuilds,
		AdminUserReportInfoComments,
	},
	props: {
		user: {
			type: Object,
		},
	},
	data: () => ({}),
	computed: {
		reports() {
			let reports = [];
			if (this.user.reportsAgainst) {
				reports = reports.concat(this.user.reportsAgainst);
			}
			if (this.user.actions) {
				reports = reports.concat(this.user.actions);
			}

			reports.sort((a, b) => {
				function getLatestDate(comments) {
					let latest = new Date(comments[0].createdAt);
					for (let i = 1; i < comments.length; i++) {
						const date = new Date(comments[i].createdAt);
						if (date > latest) {
							latest = date;
						}
					}
					return latest;
				}
				if (!a.updatedAt) {
					a.updatedAt = getLatestDate(a.comments);
				}
				if (!b.updatedAt) {
					b.updatedAt = getLatestDate(b.comments);
				}
				return b.updatedAt - a.updatedAt;
			});
			return reports;
		},
		elkUrl() {
			return `https://elkowobot.com/app/dashboards#/view/6e3447d0-245a-11ed-a686-ddea45c28ff1?embed=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-7d%2Fd,to:now))&_a=(description:'',filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:a7b4b3f0-3531-11ec-8b35-a5425d3cc1f5,key:user.keyword,negate:!f,params:(query:'${this.user.discordId}'),type:phrase),query:(match_phrase:(user.keyword:'${this.user.discordId}')))),fullScreenMode:!f,options:(hidePanelTitles:!f,syncColors:!f,useMargins:!t),panels:!((embeddableConfig:(enhancements:()),gridData:(h:15,i:'65e891d0-d4ac-4b2c-8dd8-87b57e312ca9',w:24,x:0,y:0),id:c27fd270-3552-11ec-a412-b1d9ef9fa966,panelIndex:'65e891d0-d4ac-4b2c-8dd8-87b57e312ca9',type:lens,version:'7.15.0')),query:(language:kuery,query:''),tags:!(),timeRestore:!t,title:'Admin%20Dashboard',viewMode:edit)&show-top-menu=true&show-query-input=true&show-time-filter=true`;
		},
		firstSection() {
			const creationDate = this.getCreationDate(this.user.discordId);
			const diff = new Date(new Date().getTime() - creationDate.getTime());
			const years = diff.getUTCFullYear() - 1970;
			const months = diff.getUTCMonth();
			const days = diff.getUTCDate() - 1;
			const banTime = this.user.botBan || 0;
			const banDays = Math.floor(banTime / 86400000);

			return [
				{
					key: 'Username',
					value: `${this.user.username}#${this.user.discriminator}`,
				},
				{
					key: 'Discord ID',
					value: this.user.discordId,
				},
				{
					key: 'Creation Date',
					value: creationDate.toLocaleDateString(),
				},
				{
					key: 'Account Age',
					value: `${years}Y ${months}M ${days}D old`,
				},
				{
					key: 'Total Reports',
					value: this.user.reportsAgainstCount,
				},
				{
					key: 'Reports Made',
					value: this.user.reportsCount,
				},
				{
					key: 'Appeals Made',
					value: this.user.appealsCount,
				},
				{
					key: 'Purchases',
					value: this.user.transactions.length,
				},
				{
					key: '# of Guilds',
					value: this.user.guilds.length,
				},
				{
					key: 'Email',
					value: this.user.email,
				},
				{
					key: 'Level',
					value: this.user.level,
				},
				{
					key: 'Animal Count',
					value: this.user.animalCount?.toLocaleString('en-US'),
				},
				{
					key: 'Cowoncy',
					value: this.user.cowoncy?.toLocaleString('en-US'),
				},
				{
					key: 'Cookies',
					value: this.user.cookies?.toLocaleString('en-US'),
				},
				{
					key: 'Site Ban',
					value: this.user.banned,
				},
				{
					key: 'Bot Ban Timer',
					value: `${banDays} days`,
				},
			];
		},
	},
	methods: {
		getCreationDate(id) {
			const binary = parseInt(id)
				.toString(2)
				.padStart(64, '0')
				.substring(0, 42);
			const timestamp = parseInt(binary, 2) + 1420070400000;
			return new Date(timestamp);
		},
	},
	async mounted() {},
};
</script>

<style scoped>
.page {
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 14px;
}

.page-row {
	display: flex;
	gap: 14px;
}

@media all and (max-width: 930px) {
	.page-row {
		flex-direction: column;
	}
}

.guilds {
	flex-grow: 1;
}

.info-card {
	min-width: 400px;
}

.iframe {
	width: 100%;
	height: 700px;
}

.reports {
	flex-grow: 1;
	width: 100%;
	padding: 20px;
	gap: 20px;
}
</style>
