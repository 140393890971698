<template>
	<div>
		<v-tabs>
			<v-tab @click="tab = 'guild'">Guilds</v-tab>
			<v-tab @click="tab = 'ip'">IPs</v-tab>
		</v-tabs>
		<v-data-table
			v-if="tab === 'ip'"
			:headers="ipHeaders"
			:items="ips"
			:items-per-page="8"
			:search="ipSearch"
			:item-class="rowBackground"
		>
			<template v-slot:item.loc="{ item }">
				{{ getIPLocation(item) }}
			</template>
			<template v-slot:item.org="{ item }">
				{{ getIPOrg(item) }}
			</template>
		</v-data-table>
		<v-data-table
			v-if="tab === 'guild'"
			:headers="headers"
			:items="guilds"
			:items-per-page="8"
			:search="search"
		>
			<template v-slot:top>
				<v-text-field
					v-model="search"
					label="Search"
					class="mx-4"
				></v-text-field>
			</template>
			<template v-slot:item.name="{ item }">
				<div class="guild-info">
					<v-avatar size="32">
						<v-img :src="avatarUrl(item)" alt="avatar" />
					</v-avatar>
					<div class="guild-info-name">
						{{ item.name }}
					</div>
				</div>
			</template>
			<template v-slot:item.action="{ item }">
				<AdminGuildReportModal :guild="modalSanitize(item)" />
			</template>
		</v-data-table>
	</div>
</template>

<script>
export default {
	name: 'AdminUserReportInfoGuilds',
	components: {
		AdminGuildReportModal: () =>
			import('@/views/admin/components/AdminGuildReportModal'),
	},
	props: {
		guilds: {
			type: Array,
		},
		ips: {
			type: Array,
		},
	},
	data: () => ({
		tab: 'guild',
		headers: [
			{ text: 'Guild', value: 'name' },
			{ text: 'ID', value: '_id' },
			{ text: 'Reports', value: 'reportsAgainstCount' },
			{ text: 'Action', value: 'action' },
		],
		ipHeaders: [
			{ text: 'IP', value: 'ip' },
			{ text: 'Location', value: 'loc' },
			{ text: 'Org', value: 'org' },
		],
		search: '',
		ipSearch: '',
	}),
	methods: {
		rowBackground(item) {
			if (item.banned) {
				return 'banned-row';
			}
			return '';
		},
		modalSanitize(guild) {
			return {
				id: guild._id,
				avatar: guild.avatar,
				avatarUrl: this.avatarUrl(guild),
				name: guild.name,
				reports: guild.reportsAgainstCount,
			};
		},
		avatarUrl(guild) {
			return `https://cdn.discordapp.com/icons/${guild._id}/${guild.avatar}.png`;
		},
		getIPLocation(ip) {
			let data = ip.data;
			if (!data) return '-';
			data = JSON.parse(ip.data);
			return `${data.city} / ${data.region} / ${data.country_name}`;
		},
		getIPOrg(ip) {
			let data = ip.data;
			if (!data) return '-';
			data = JSON.parse(ip.data);
			return data.org;
		},
	},
};
</script>

<style scoped>
.name {
	display: flex;
}

.table {
	display: flex;
	gap: 20px;
}

.count {
	flex-grow: 1;
}

.guild-info {
	display: flex;
}

.guild-info-name {
	padding-top: 6px;
	padding-left: 10px;
}

::v-deep .banned-row {
	background-color: rgba(255, 0, 0, 0.2);
}
</style>
