<template>
	<div>
		<v-dialog
			v-model="dialog"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					color="primary"
					v-bind="attrs"
					v-on="on"
					@click="openDialog"
					:class="{ hidden: isOverride }"
				>
					<v-icon> mdi-information </v-icon>
				</v-btn>
			</template>
			<v-card color="background">
				<v-toolbar color="primary">
					<v-btn icon dark @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>

					<v-toolbar-title>
						<v-avatar size="32">
							<v-img :src="user.avatarUrl" alt="avatar" />
						</v-avatar>
						Reports for <b>{{ user.fullUsername }}</b>
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<AdminUserElkButton :id="user.id || user.discordId" />
					<AdminUserActionButton class="ml-2" :user="user" @refresh="getInfo" />
					<v-btn class="ml-2" icon @click="getInfo" :loading="fetching">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
				</v-toolbar>
				<div v-if="fetching" class="fetching">
					<v-progress-circular
						class="mb-10 mt-3"
						:size="70"
						color="primary"
						indeterminate
					></v-progress-circular>
				</div>
				<div v-else>
					<AdminUserReportInfo :user="info" @refresh="getInfo" />
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import AdminUserReportInfo from '@/views/admin/components/AdminUserReportInfo';
import AdminUserElkButton from '@/views/admin/components/AdminUserElkButton';
import AdminUserActionButton from '@/views/admin/components/AdminUserActionButton';

export default {
	name: 'AdminUserReportModal',
	components: {
		AdminUserReportInfo,
		AdminUserElkButton,
		AdminUserActionButton,
	},
	props: {
		user: {
			type: Object,
		},
		isOverride: {
			type: Boolean,
		},
	},
	data: () => ({
		dialog: false,
		fetching: false,
		info: {},
	}),
	methods: {
		async openDialog() {
			this.getInfo();
		},
		async getInfo() {
			this.fetching = true;
			try {
				this.info = await this.$store.dispatch(
					'getUserReport',
					this.user.id || this.user.discordId
				);
			} catch (err) {
				// TODO MODAL
				console.error(err);
			} finally {
				this.fetching = false;
			}
		},
	},
	mounted() {
		if (this.isOverride && this.user) {
			this.openDialog();
			this.dialog = true;
		}
	},
};
</script>

<style scoped>
.fetching {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.hidden {
	visibility: hidden;
}
</style>
