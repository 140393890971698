<template>
	<v-btn :href="elkUrl" target="_blank" color="secondary">
		Elk
		<v-icon small class="ml-2">mdi-open-in-new</v-icon>
	</v-btn>
</template>

<script>
export default {
	name: 'AdminUserElkButton',
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	computed: {
		elkUrl() {
			return `https://elkowobot.com/app/dashboards#/view/6e3447d0-245a-11ed-a686-ddea45c28ff1?embed=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-7d%2Fd,to:now))&_a=(description:'',filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:a7b4b3f0-3531-11ec-8b35-a5425d3cc1f5,key:user.keyword,negate:!f,params:(query:'${this.id}'),type:phrase),query:(match_phrase:(user.keyword:'${this.id}')))),fullScreenMode:!f,options:(hidePanelTitles:!f,syncColors:!f,useMargins:!t),panels:!((embeddableConfig:(enhancements:()),gridData:(h:15,i:'65e891d0-d4ac-4b2c-8dd8-87b57e312ca9',w:24,x:0,y:0),id:c27fd270-3552-11ec-a412-b1d9ef9fa966,panelIndex:'65e891d0-d4ac-4b2c-8dd8-87b57e312ca9',type:lens,version:'7.15.0')),query:(language:kuery,query:''),tags:!(),timeRestore:!t,title:'Admin%20Dashboard',viewMode:edit)&show-top-menu=true&show-query-input=true&show-time-filter=true`;
		},
	},
};
</script>

<style scoped></style>
